import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { isTokenExpired, logout } from '../../utils/auth';
import './AdminDashboard.css'; // Import the CSS for AdminDashboard

const AdminDashboard = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isTokenExpired()) {
      logout();
      navigate('/login');
    }
  }, [navigate]);

  return (
    <div className="full-page-container">
      <div className="dashboard-container">
        <h2 className="dashboard-header">Admin Dashboard</h2>
        <ul className="dashboard-list">
          <li><Link to="/admin/statistics">Site Statistics</Link></li>
          <li><Link to="/admin/articles">Manage Articles</Link></li>
          <li><Link to="/admin/articles/create">Create Article</Link></li>
        </ul>
      </div>
    </div>
  );
};

export default AdminDashboard;
