import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './Navbar.css';
import navbarBackground from './navbar_background.png';

const Navbar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const categories = ['business', 'politics', 'entertainment', 'lifestyle', 'technology', 'all'];

  return (
    <nav className="navbar" style={{ backgroundImage: `url(${navbarBackground})` }}>
      <Link to="/" className="navbar-brand">
        PRT
      </Link>
      <button
        className={`navbar-toggler ${isNavExpanded ? "expanded" : ""}`}
        onClick={() => setIsNavExpanded(!isNavExpanded)}
        aria-label="Toggle navigation"
      >
        <div className="hamburger">
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </button>
      <div className={`navbar-links ${isNavExpanded ? "expanded" : ""}`}>
        {categories.map((category) => (
          <NavLink
            key={category}
            to={category === 'all' ? '/articles' : `/category/${category}`}
            className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
            onClick={() => setIsNavExpanded(false)}
          >
            {category.charAt(0).toUpperCase() + category.slice(1)}
          </NavLink>
        ))}
      </div>
    </nav>
  );
};

export default Navbar;
