import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { isTokenExpired, logout } from '../../utils/auth';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles for ReactQuill
import './EditArticle.css'; // Import the CSS for EditArticle
import DOMPurify from 'dompurify';

const EditArticle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState({
    title: '',
    content: '',
    author: '',
    category: '',
    photo: null,
  });
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (isTokenExpired()) {
      logout();
      navigate('/login');
    }

    const fetchArticleAndCategories = async () => {
      try {
        const token = localStorage.getItem('token');
        const articleResponse = await fetch(`${process.env.REACT_APP_API_URL}/articles/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const articleData = await articleResponse.json();
        setArticle({
          ...articleData,
          category: articleData.category._id // Ensure the category ID is set correctly
        });

        const categoriesResponse = await fetch(`${process.env.REACT_APP_API_URL}/categories`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const categoriesData = await categoriesResponse.json();
        setCategories(categoriesData);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        alert('Error fetching article data');
      }
    };

    fetchArticleAndCategories();
  }, [id, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setArticle(prevArticle => ({
      ...prevArticle,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setArticle(prevArticle => ({
      ...prevArticle,
      photo: e.target.files[0],
    }));
  };

  const handleContentChange = (content) => {
    setArticle(prevArticle => ({
      ...prevArticle,
      content: DOMPurify.sanitize(content),
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('title', article.title);
    formData.append('content', article.content);
    formData.append('author', article.author);
    formData.append('category', article.category); // Ensure category ID is sent
    if (article.photo) {
      formData.append('photo', article.photo);
    }

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/articles/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });
      if (response.ok) {
        alert('Article updated successfully!');
        navigate('/admin/articles'); // Redirect to manage articles page
      } else {
        const errorData = await response.json();
        console.error('Failed to update article:', errorData);
        alert(`Failed to update article: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error updating article');
    }
  };

  if (loading) return <p>Loading...</p>;
  if (!article) return <p>Article not found!</p>;

  return (
    <div className="full-page-container">
      <div className="edit-article-container">
        <h2>Edit Article</h2>
        <form className="edit-article-form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="title"
            value={article.title}
            onChange={handleChange}
            placeholder="Title"
            required
          />
          <ReactQuill
            value={article.content}
            onChange={handleContentChange}
            placeholder="Content"
            required
          />
          <input
            type="text"
            name="author"
            value={article.author}
            onChange={handleChange}
            placeholder="Author"
            required
          />
          <select
            name="category"
            value={article.category} // Ensure the correct value is set
            onChange={handleChange}
            required
          >
            <option value="">Select Category</option>
            {categories.map(cat => (
              <option key={cat._id} value={cat._id}>{cat.name}</option>
            ))}
          </select>
          <input
            type="file"
            name="photo"
            onChange={handleFileChange}
          />
          <button type="submit">Update Article</button>
        </form>
      </div>
    </div>
  );
};

export default EditArticle;

