// ArticlePreview.js
import React from 'react';
import { Link } from 'react-router-dom';
import './ArticlePreview.css'; // Ensure this path is correct

const ArticlePreview = ({ _id, title, excerpt, imageUrl, isLarge, categoryView = false, author, publishedDate }) => {
  const previewClass = `${isLarge ? "articlePreview articlePreviewLarge" : "articlePreview"} ${categoryView ? "categoryView" : ""}`;

  // Remove the API URL prepending, use the imageUrl directly
  const fullImageUrl = imageUrl || null;

  // Format the date
  const displayDate = new Date(publishedDate).toLocaleDateString("en-US", {
    year: 'numeric', month: 'long', day: 'numeric'
  });

  return (
    <div className={previewClass} id="article-preview-container">
      <div className="articleImageContainer">
        {fullImageUrl && <img src={fullImageUrl} alt={title} className="articleImage" />}
      </div>
      <div className="articleInfo">
        <h3 className="articleTitle">{title}</h3>
        <div className="articleAuthorDate">
          <span>{author} - {displayDate}</span>
        </div>
        <p className="articleExcerpt">{excerpt}</p>
        <Link to={`/article/${_id}`} className="readMoreLink">Read More</Link>
      </div>
    </div>
  );
};

export default ArticlePreview;