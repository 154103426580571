import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import HeroSection from './HeroSection';
import ArticleList from './ArticleList';

const AllArticlesView = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/articles`);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        setArticles(data);
      } catch (error) {
        console.error("Could not fetch articles: ", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!articles.length) return <div>No articles found.</div>;

  return (
    <div>
      <Helmet>
        <title>All Articles - Personal Research Times</title>
        <meta name="description" content="Discover the whole shabang of articles from Personal Research Times." />
      </Helmet>
      <HeroSection 
        title="Explore All Articles"
        description="Discover the whole shabang."
        backgroundColor="#f5f5f5" // Light grey background as defined for 'All'
        textColor="#333333" // Dark grey text as defined for 'All'
        showBackgroundImage={false}
      />
      <ArticleList articles={articles} singleColumn={true} />
    </div>
  );
};

export default AllArticlesView;