import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HeroSection from './HeroSection';
import ArticleList from './ArticleList';

const categoryColors = {
  Business: { background: '#E2F1DD', text: '#0D3818' },  // Dark green on light green
  Politics: { background: '#faebd7', text: '#0A283E' },  // Dark blue on light beige
  Entertainment: { background: '#FFEEBC', text: '#402000' },  // Dark brown on light yellow
  Lifestyle: { background: '#ffe4e1', text: '#5C1010' },  // Dark red on light pink
  Technology: { background: '#DBE1E8', text: '#0A1F33' },  // Dark navy on light blue-grey
  All: { background: '#f5f5f5', text: '#333333' }  // Dark grey on light grey
};

const categoryDetails = {
  Business: {
    title: "Explore Business",
    description: "Unusual takes on the current business as usual norms."
  },
  Politics: {
    title: "Explore Politics",
    description: "Fighting for your right to party beyond our current two-party system."
  },
  Entertainment: {
    title: "Explore Entertainment",
    description: "Get our latest thoughts on movies, music, and more."
  },
  Lifestyle: {
    title: "Explore Lifestyle",
    description: "Tips and ideas for living more deliberately."
  },
  Technology: {
    title: "Explore Technology",
    description: "A somewhat dull edge take on technology and innovation."
  }
};

const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, char => char.toUpperCase());
};

const CategoryView = () => {
  const { categoryName } = useParams();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/articles/category/${categoryName.toLowerCase()}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setArticles(data);
      } catch (error) {
        console.error("Could not fetch articles for category: ", error);
      }
    };

    fetchArticles();
  }, [categoryName]);

  const capitalizedCategoryName = capitalizeWords(categoryName);
  const categoryInfo = categoryDetails[capitalizedCategoryName] || {
    title: `Explore ${capitalizedCategoryName}`,
    description: `Discover all articles about ${capitalizedCategoryName}.`
  };

  const categoryStyle = categoryColors[capitalizedCategoryName] || { background: '#f5f5f5', text: '#333333' };

  return (
    <div>
      <Helmet>
        <title>{`${categoryInfo.title} - Personal Research Times`}</title>
        <meta name="description" content={categoryInfo.description} />
      </Helmet>
      <HeroSection 
        title={categoryInfo.title}
        description={categoryInfo.description}
        backgroundColor={categoryStyle.background}
        textColor={categoryStyle.text}
        showBackgroundImage={false}
      />
      <ArticleList articles={articles} singleColumn={true} categoryContext={true} />
    </div>
  );
};

export default CategoryView;
