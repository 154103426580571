// src/components/About.js
import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <h1 className="about-title">About Personal Research Times</h1>
      <div className="about-content">
        <p>Welcome to Personal Research Times, your source for curated content full of carefully considered opinions and insights.</p>
        <p>Our mission is to provide readers with thought-provoking articles across various topics including Business, Politics, Entertainment, Lifestyle, and Technology.</p>
        <p>At Personal Research Times, we believe in:</p>
        <ul>
          <li>Delivering high-quality, well-researched content</li>
          <li>Offering diverse perspectives on complex issues</li>
          <li>Encouraging critical thinking and informed discussions</li>
          <li>Staying at the forefront of emerging trends and developments</li>
        </ul>
        <p>Thank you for being a part of our community of curious and discerning readers.</p>
      </div>
    </div>
  );
};

export default About;