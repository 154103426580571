function isTokenExpired() {
  const expiryTime = localStorage.getItem('tokenExpiry');
  if (!expiryTime) {
    return true; // Assume expired if no expiry time is found
  }
  const now = new Date();
  return now.getTime() > parseInt(expiryTime);
}

function getToken() {
  return localStorage.getItem('token');
}

function logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('tokenExpiry');
  // Redirect to login or perform other cleanup actions
}

export { isTokenExpired, getToken, logout };

