// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section">
        <Link to="/about" className="footer-link">ABOUT</Link>
      </div>
      <div className="footer-section">
        <span className="footer-text">personalresearchtimes.com</span>
      </div>
      <div className="footer-section">
        {/* Placeholder for future social media links */}
      </div>
    </footer>
  );
};

export default Footer;
