import React from 'react';
import './HeroSection.css';
import Lottie from 'react-lottie';
import animationData from './newsletter-animation.json'; // Path to your Lottie JSON file

const HeroSection = ({ title, description, backgroundColor, textColor, showBackgroundAnimation = false }) => {
  const backgroundStyle = {
    backgroundColor,
    color: textColor  // Apply text color
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <section className="hero" style={backgroundStyle}>
      {showBackgroundAnimation && (
        <div className="hero-background">
          <Lottie options={defaultOptions} height="100%" width="100%" />
        </div>
      )}
      <div className="hero-content">
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
    </section>
  );
};

export default HeroSection;