import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isTokenExpired, logout } from '../../utils/auth';

const SiteStatistics = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isTokenExpired()) {
      logout();
      navigate('/login');
    }
  }, [navigate]);

  return (
    <div>
      <h2>Site Statistics</h2>
      {/* Statistics display logic */}
    </div>
  );
};

export default SiteStatistics;

