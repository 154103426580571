import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { isTokenExpired, logout } from '../../utils/auth';
import './ManageArticles.css'; // Import the CSS for ManageArticles

const ManageArticles = () => {
  const [articles, setArticles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (isTokenExpired()) {
      logout();
      navigate('/login');
    }

    fetchArticles();
  }, [navigate]);

  const fetchArticles = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/articles`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });
      if (response.ok) {
        const data = await response.json();
        setArticles(data);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch articles');
      }
    } catch (error) {
      console.error('Error fetching articles:', error);
      alert(`Error fetching articles: ${error.message}`);
    }
  };

  const deleteArticle = async (id) => {
    if (window.confirm("Are you sure you want to delete this article?")) {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_API_URL}/articles/${id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        });
        if (response.ok) {
          setArticles(articles.filter(article => article._id !== id));
          alert('Article deleted successfully!');
        } else {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to delete article');
        }
      } catch (error) {
        console.error('Error deleting article:', error);
        alert(`Error deleting article: ${error.message}`);
      }
    }
  };

  return (
    <div className="full-page-container">
      <div className="articles-container">
        <h2 className="articles-header">Manage Articles</h2>
        <div className="articles-grid">
          {articles.map(article => (
            <div key={article._id} className="article-card">
              <div className="article-title">{article.title}</div>
              <div className="article-actions">
                <Link to={`/admin/articles/edit/${article._id}`} className="article-link">Edit</Link>
                <button className="article-button" onClick={() => deleteArticle(article._id)}>Delete</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ManageArticles;
